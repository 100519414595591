<template>
	<div class="min-h-screen relative">
		<div class="__setup-center px-12 md-down:px-4 pb-30 md:w-1/2">
			<div
				class="
					__setup-center__header
					pt-10
					pb-4
					flex
					justify-between
					gap-x-20 gap-y-8
					items-center
					flex-wrap
					xl:flex-nowrap
				"
			>
				<div class="flex items-center w-full md-down:justify-between">
					<div
						class="
							__record__title
							text-neutral-500
							font-semibold
							whitespace-nowrap
							flex flex-nowrap
							items-center
							text-4xl
							cursor-pointer
						"
						@click="$router.back()"
					>
						<svg
							class="mr-2"
							fill="none"
							height="28"
							viewBox="0 0 28 28"
							width="28"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.5 7L10.5 14L17.5 21"
								stroke="#94A3B8"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
							/>
						</svg>
						{{ group_id ? 'Chỉnh sửa nhóm' : 'Tạo nhóm' }}
					</div>
					<button
						class="button-p text-center btn--ghost w-32 md:hidden"
						type="button"
						@click="showModal = true"
					>
						Xem danh sách
					</button>
				</div>
				<div class="search-input w-full xl:max-w-lg">
					<div class="input-group">
						<input
							v-model="searchQuery"
							class="bg-white input-with-icon"
							placeholder="Tìm theo mục tiêu"
							type="text"
							@keyup="searching"
							@keypress.enter="search"
						/>
						<div class="input-icon absolute top-1/2">
							<DaIcon
								class="h-4 w-4 text-neutral-400"
								name="das--search"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-6">
				<!--				<div>
                  <div class="input-group input&#45;&#45;lg">
                    <div class="input-group__label">Tên nhóm</div>
                    <input
                      type="text"
                      placeholder="Không được trùng với tên nhóm đã tồn tại trước đó"
                      class="bg-white"
                      v-model="name"
                      :class="errorName ? 'input&#45;&#45;error' : ''"
                    />
                  </div>
                  <div
                    v-if="errorName"
                    class="error text-sienna-600 text-sm w-full mt-2"
                  >
                    {{ errorName }}
                  </div>
                </div>-->
				<div class="mt-14 text-neutral-700 font-semibold">
					Chọn các mục tiêu đang có trong
					{{
						type === 'library'
							? 'Ngân hàng mục tiêu'
							: 'Kế hoạch hiện tại'
					}}
					mà bạn muốn thêm vào nhóm
				</div>
				<div class="mt-8">
					<div v-if="goals && goals.length > 0">
						<div>
							<div
								v-for="(goal, index) in goals.filter(
									(s) =>
										s.goals.filter(
											(i) => !isSelected(s.skill, i)
										)?.length
								)"
								:key="index"
								class="mb-10"
							>
								<div
									class="
										font-semibold
										text-lg text-neutral-600
										mb-4
									"
								>
									{{ index + 1 }}. {{ goal.skill.name }} ({{
										goal.goals.length
									}})
								</div>
								<div
									class="
										shadow-sm
										sm:rounded-2xl
										overflow-hidden
									"
								>
									<div
										class="
											__record__table
											overflow-x-auto
											scroll-bar
										"
									>
										<div
											class="
												overflow-hidden
												sm:rounded-t-2xl
												w-fit
												min-w-full
											"
										>
											<table
												class="
													min-w-full
													divide-y
													text-neutral-200
												"
											>
												<thead
													class="bg-neutral-100 h-16"
												>
													<tr>
														<th
															class="
																px-6
																py-3
																text-left
																text-xs
																font-semibold
																text-neutral-600
																uppercase
																tracking-wider
																w-16
																whitespace-nowrap
															"
															scope="col"
														>
															STT
														</th>
														<th
															class="
																px-6
																py-3
																text-left
																text-xs
																font-semibold
																text-neutral-600
																uppercase
																tracking-wider
																whitespace-nowrap
															"
															scope="col"
															style="
																min-width: 160px;
															"
														>
															Nội dung
														</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="(
															g, i
														) in goal.goals.filter(
															(i) =>
																!isSelected(
																	goal.skill,
																	i
																)
														)"
														:key="g.goal_id"
														:class="
															i % 2 === 0
																? 'bg-white'
																: 'bg-neutral-50'
														"
														class="
															text-neutral-700
															hover:bg-orange-50
															cursor-pointer
														"
													>
														<td
															class="
																px-6
																py-4
																whitespace-nowrap
																text-neutral-700
															"
														>
															<div class="ml-2">
																{{ i + 1 }}
															</div>
														</td>
														<td class="px-6 py-4">
															<div
																class="
																	flex
																	items-center
																"
															>
																<router-link
																	:to="`/group-class/${id}/${
																		g.goal_id ||
																		g.id
																	}`"
																>
																	<div
																		class="
																			mr-2
																			hover:text-yellow-700
																			hover:underline
																		"
																	>
																		{{
																			g.goal_name ||
																			g.name
																		}}
																	</div>
																</router-link>
																<TooltipGoalDetail
																	:goal-id="
																		g.goal_id ||
																		g.id
																	"
																	:student-id="
																		id
																	"
																/>
															</div>
															<div
																class="
																	flex
																	gap-2
																	flex-wrap
																"
																style="
																	max-width: 360px;
																"
															>
																<!-- <div
																	v-if="
																		g?.raw_source
																	"
																	class="
																		mt-1
																		py-0.5
																		px-2.5
																		rounded-full
																		bg-neutral-200
																		text-neutral-700
																		h-fit
																		w-fit
																		whitespace-nowrap
																		font-semibold
																		shadow-input
																		whitespace-nowrap
																	"
																	style="
																		font-size: 10px;
																	"
																>
																	{{
																		g?.display_source
																	}}
																</div> -->
																<div
																	v-if="
																		g?.state
																	"
																	class="
																		mt-1
																		py-0.5
																		px-2.5
																		rounded-full
																		bg-neutral-200
																		text-neutral-700
																		h-fit
																		w-fit
																		whitespace-nowrap
																		font-semibold
																		shadow-input
																	"
																	style="
																		font-size: 10px;
																	"
																>
																	{{
																		$filters.sourceMap(
																			g?.state,
																			g.editable
																		)
																	}}
																</div>
																<template
																	v-if="
																		g?.assistance_levels &&
																		org.enable_goal_assistance_level
																	"
																	class="
																		flex
																		gap-2
																	"
																>
																	<div
																		v-for="ass in g?.assistance_levels"
																		:key="
																			ass
																		"
																	>
																		<div
																			class="
																				mt-1
																				py-0.5
																				px-2.5
																				rounded-full
																				bg-pink-100
																				whitespace-nowrap
																				text-neutral-700
																				h-fit
																				w-fit
																				font-semibold
																				shadow-input
																			"
																			style="
																				font-size: 10px;
																			"
																		>
																			{{
																				ass
																			}}
																		</div>
																	</div>
																</template>
															</div>
															<div
																v-if="
																	g.parent
																		?.sub_goal_count >
																	0
																"
																class="
																	goal-block
																	flex
																	gap-3
																	mt-2
																"
															>
																<img
																	class="
																		w-3
																		h-3
																		mt-1
																	"
																	src="@/assets/images/icons/goal-arrow.png"
																	alt=""
																/>
																<div>
																	<div
																		class="
																			text-neutral-700
																		"
																	>
																		{{
																			g
																				.parent
																				?.name
																		}}
																	</div>
																	<div
																		class="
																			text-neutral-400
																			text-xs
																			font-semibold
																		"
																	>
																		{{
																			g
																				.parent
																				?.sub_goal_count
																		}}
																		MỤC TIÊU
																	</div>
																</div>
															</div>
														</td>
														<td style="width: 60px">
															<Checkbox
																:value="
																	isSelected(
																		goal.skill,
																		g
																	)
																"
																@click.stop="
																	onSelect(
																		goal,
																		g
																	)
																"
															/>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			:class="showModal ? 'right-0' : '-right-full md:right-0'"
			class="
				bg-white
				md-down:px-8 md-down:py-16
				p-12
				fixed
				top-0
				h-screen
				w-screen
				md:w-1/2
				overflow-y-auto
				flex flex-col
				justify-between
				duration-300
			"
		>
			<div class="relative">
				<div
					class="absolute -top-8 -right-1 md:hidden"
					@click="showModal = false"
				>
					<svg
						fill="none"
						height="28"
						viewBox="0 0 28 28"
						width="28"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M8.71463 8.71453C9.05633 8.37282 9.61035 8.37282 9.95206 8.71453L14.5833 13.3458L19.2146 8.71453C19.5563 8.37282 20.1104 8.37282 20.4521 8.71453C20.7938 9.05624 20.7938 9.61026 20.4521 9.95197L15.8208 14.5833L20.4521 19.2145C20.7938 19.5562 20.7938 20.1103 20.4521 20.452C20.1104 20.7937 19.5563 20.7937 19.2146 20.452L14.5833 15.8207L9.95206 20.452C9.61035 20.7937 9.05633 20.7937 8.71463 20.452C8.37292 20.1103 8.37292 19.5562 8.71463 19.2145L13.3459 14.5833L8.71463 9.95197C8.37292 9.61026 8.37292 9.05624 8.71463 8.71453Z"
							fill="#94A3B8"
							fill-rule="evenodd"
						/>
					</svg>
				</div>
				<div>
					<div class="input-group input--lg">
						<div class="input-group__label">Tên nhóm</div>
						<input
							v-model="name"
							:class="errorName ? 'input--error' : ''"
							class="bg-white"
							placeholder="Không được trùng với tên nhóm đã tồn tại trước đó"
							type="text"
						/>
					</div>
					<div
						v-if="errorName"
						class="error text-sienna-600 text-sm w-full mt-2"
					>
						{{ errorName }}
					</div>
				</div>
				<div class="mt-8 flex items-center justify-between">
					<div class="font-semibold text-neutral-600">
						Danh sách mục tiêu đã chọn
					</div>
				</div>
				<div class="mt-6 mb-20">
					<div v-if="selected && selected.length > 0">
						<div>
							<div
								v-for="(goal, index) in selected"
								:key="index"
								class="mb-10"
							>
								<div
									class="
										font-semibold
										text-lg text-neutral-600
										mb-4
									"
								>
									{{ index + 1 }}. {{ goal.skill?.name }} ({{
										goal.goals?.length
									}})
								</div>
								<div
									class="
										shadow-sm
										sm:rounded-2xl
										overflow-hidden
									"
								>
									<div
										class="
											__record__table
											overflow-x-auto
											scroll-bar
										"
									>
										<div
											class="
												overflow-hidden
												sm:rounded-t-2xl
												w-fit
												min-w-full
											"
										>
											<table
												class="
													min-w-full
													divide-y
													text-neutral-200
												"
											>
												<thead
													class="bg-neutral-100 h-16"
												>
													<tr>
														<th
															class="
																px-6
																py-3
																text-left
																text-xs
																font-semibold
																text-neutral-600
																uppercase
																tracking-wider
																w-16
																whitespace-nowrap
															"
															scope="col"
														>
															STT
														</th>
														<th
															class="
																px-6
																py-3
																text-left
																text-xs
																font-semibold
																text-neutral-600
																uppercase
																tracking-wider
																whitespace-nowrap
															"
															scope="col"
															style="
																min-width: 160px;
															"
														>
															Nội dung
														</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="(
															g, i
														) in goal.goals"
														:key="g.goal_id"
														:class="
															i % 2 === 0
																? 'bg-white'
																: 'bg-neutral-50'
														"
														class="
															text-neutral-700
															hover:bg-orange-50
															cursor-pointer
														"
														@click="
															$router.push(
																`/group-class/${id}/${
																	g.goal_id ||
																	g.id
																}`
															)
														"
													>
														<td
															class="
																px-6
																py-4
																whitespace-nowrap
																text-neutral-700
															"
														>
															<div class="ml-2">
																{{ i + 1 }}
															</div>
														</td>
														<td
															class="
																px-6
																py-4
																text-neutral-700
															"
														>
															<div
																class="
																	flex
																	items-center
																"
															>
																<div
																	class="mr-2"
																>
																	{{
																		g.goal_name ||
																		g.name
																	}}
																</div>
																<TooltipGoalDetail
																	:goal-id="
																		g.goal_id ||
																		g.id
																	"
																	:student-id="
																		id
																	"
																/>
															</div>
															<div
																class="
																	flex
																	gap-2
																	flex-wrap
																"
																style="
																	max-width: 360px;
																"
															>
																<div
																	v-if="
																		g?.raw_source
																	"
																	class="
																		mt-1
																		py-0.5
																		px-2.5
																		rounded-full
																		bg-neutral-200
																		text-neutral-700
																		h-fit
																		w-fit
																		whitespace-nowrap
																		font-semibold
																		shadow-input
																		whitespace-nowrap
																	"
																	style="
																		font-size: 10px;
																	"
																>
																	{{
																		g?.display_source
																	}}
																</div>
																<div
																	v-if="
																		g?.state
																	"
																	class="
																		mt-1
																		py-0.5
																		px-2.5
																		rounded-full
																		bg-neutral-200
																		text-neutral-700
																		h-fit
																		w-fit
																		whitespace-nowrap
																		font-semibold
																		shadow-input
																		whitespace-nowrap
																	"
																	style="
																		font-size: 10px;
																	"
																>
																	{{
																		$filters.sourceMap(
																			g?.state,
																			g.editable
																		)
																	}}
																</div>
																<template
																	v-if="
																		g?.assistance_levels &&
																		org.enable_goal_assistance_level
																	"
																	class="
																		flex
																		gap-2
																	"
																>
																	<div
																		v-for="ass in g?.assistance_levels"
																		:key="
																			ass
																		"
																	>
																		<div
																			class="
																				mt-1
																				py-0.5
																				px-2.5
																				rounded-full
																				bg-pink-100
																				whitespace-nowrap
																				whitespace-nowrap
																				text-neutral-700
																				h-fit
																				w-fit
																				font-semibold
																				shadow-input
																			"
																			style="
																				font-size: 10px;
																			"
																		>
																			{{
																				ass
																			}}
																		</div>
																	</div>
																</template>
															</div>
														</td>
														<td style="width: 60px">
															<Checkbox
																:value="
																	isSelected(
																		goal.skill,
																		g
																	)
																"
																@click.stop="
																	onSelect(
																		goal,
																		g
																	)
																"
															/>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="
					flex flex-col
					items-end
					justify-end
					bottom-0
					right-0
					w-full
					gap-4
				"
			>
				<div class="text-neutral-400">
					*Đặt tên nhóm và chọn ít nhất 1 mục tiêu để tạo nhóm
				</div>
				<button
					:disabled="!name || !selected.length"
					class="button-p text-center whitespace-nowrap w-56"
					type="button"
					@click="submit"
				>
					Xác nhận
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail';
import Checkbox from '@/components/elements/checkbox/Checkbox';
import DaIcon from '@/components/elements/daIcon/DaIcon';

export default {
	name: 'CreateGroup',

	components: { DaIcon, TooltipGoalDetail, Checkbox },

	data() {
		return {
			name: '',
			errorName: '',
			searchQuery: '',
			isLoading: false,
			goals: [],
			selected: [],
			showModal: false,
		};
	},

	computed: {
		id() {
			return this.$route.params?.id;
		},

		type() {
			return this.$route.query?.type;
		},

		studentId() {
			return this.$route.query?.student_id;
		},

		org() {
			return this.$store.state.profile.current_org;
		},

		group_id() {
			return this.$route.query?.group_id;
		},
	},

	created() {
		const { query } = this.$route;
		if (query?.group_id) {
			this.fetchGroup();
		}
		this.fetchPlans();
	},

	methods: {
		async fetchGroup() {
			try {
				const {
					data: {
						data: { name, goals_group_by_skill },
					},
				} = await $api.goal.getDetailGroup(
					this.studentId,
					this.group_id
				);
				this.name = name || '';
				this.selected = goals_group_by_skill;
			} catch (e) {
				console.log(e);
			}
		},

		async fetchPlans(payload) {
			try {
				const {
					data: {
						data: { goals_group_by_skill },
					},
				} =
					this.type === 'library'
						? await $api.goal.getDetailGroupInLibs(
								this.studentId,
								payload || {}
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  )
						: // eslint-disable-next-line no-mixed-spaces-and-tabs
						  await $api.goal.getDetailGroup(
								this.studentId,
								'no_have_group',
								payload || {}
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  );
				this.goals = goals_group_by_skill;
				this.isLoading = false;
			} catch (e) {
				console.log(e);
			}
		},

		onSelect(data, goal) {
			const skillSelected = this.selected.find(
				(s) => s.skill?.id === data.skill.id
			);
			if (!skillSelected) {
				return this.selected.push({
					skill: data.skill,
					goals: [goal],
				});
			}
			const index = skillSelected?.goals.findIndex((g) => {
				const gid = g.id || g.goal_id;
				const goal_id = goal.id || goal.goal_id;
				return gid === goal_id;
			});
			if (index === -1) {
				skillSelected.goals.push(goal);
			} else {
				skillSelected.goals.splice(index, 1);
				if (!skillSelected.goals.length) {
					this.selected = this.selected.filter(
						(s) => s.skill?.id !== skillSelected.skill?.id
					);
				}
			}
		},

		isSelected(skill, goal) {
			const skillSelected = this.selected.find(
				(s) => s.skill?.id === skill.id
			);
			if (!skillSelected) {
				return false;
			}
			const index = skillSelected?.goals.findIndex((g) => {
				const gid = g.id || g.goal_id;
				const goal_id = goal.id || goal.goal_id;
				return gid === goal_id;
			});
			return index !== -1;
		},

		async submit() {
			try {
				if (this.group_id) {
					return this.update();
				}
				const payload = [];
				for (let i = 0; i < this.selected.length; i++) {
					payload.push(...this.selected[i].goals);
				}
				await $api.goal.createGroup(this.studentId, {
					goals: payload.map((s) => {
						return {
							id: s.goal_id || s.id,
							plan_id: +this.id || undefined,
							skill_id: s.skill_id || s?.skill?.id,
						};
					}),
					name: this.name,
					plan_id: +this.id || undefined,
				});
				this.$store.dispatch('setAlertMessage', {
					message: 'Đã tạo thành công nhóm mục tiêu',
				});
				await this.$router.push(
					`/plans/${this.studentId}${
						this.type === 'library' ? '/goal-bank' : ''
					}`
				);
			} catch (e) {
				if (e?.response?.data?.errors_by_field['name']) {
					this.errorName =
						'Tên nhóm mục tiêu không được trùng với tên nhóm đã có trước đó';
					setTimeout(() => {
						this.errorName = '';
					}, 3000);
				} else {
					this.$store.dispatch('setAlertMessage', {
						message:
							e?.response?.data?.errors?.join('<br/>') ||
							'Đã có lỗi xảy ra. Vui lòng thử lại',
						type: 'danger',
					});
				}
			}
		},

		search() {
			this.fetchPlans({
				name: this.searchQuery,
			});
		},

		searching() {
			clearInterval(this.typing);
			this.typing = setTimeout(() => {
				this.search();
			}, 100);
		},

		async update() {
			try {
				const payload = [];
				for (let i = 0; i < this.selected.length; i++) {
					payload.push(...this.selected[i].goals);
				}
				await $api.goal.updateNameGroup(this.studentId, this.group_id, {
					name: this.name,
				});
				await $api.goal.updateGoalIntoGroup(
					this.studentId,
					this.group_id,
					{
						goals: payload.map((s) => {
							return {
								id: s.goal_id || s.id,
								plan_id: +this.id || undefined,
								skill_id: s.skill_id || s?.skill?.id,
							};
						}),
					}
				);
				this.$store.dispatch('setAlertMessage', {
					message: 'Chỉnh sửa thành công nhóm mục tiêu',
				});
				await this.$router.push(
					`/plans/${this.studentId}${
						this.type === 'library' ? '/goal-bank' : ''
					}`
				);
			} catch (e) {
				if (e?.response?.data?.errors_by_field['name']) {
					this.errorName =
						'Tên nhóm mục tiêu không được trùng với tên nhóm đã có trước đó';
					setTimeout(() => {
						this.errorName = '';
					}, 3000);
				} else {
					this.$store.dispatch('setAlertMessage', {
						message:
							e?.response?.data?.errors?.join('<br/>') ||
							'Đã có lỗi xảy ra. Vui lòng thử lại',
						type: 'danger',
					});
				}
			}
		},
	},
};
</script>
<style scoped>
.goal-block {
	background: #eef3f8;
	padding: 12px;
	border-radius: 8px;
	border: 1px solid #bacde4;
}
</style>
